<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-col cols="12" md="12">
      <base-material-card color="#cf9602" class="px-5 py-3">
        <template v-slot:heading>
          <div class="text-h3 font-weight-light">Tickets</div>
        </template>
        <v-card-text>
          <v-data-table
            :headers="columns"
            :items="customerTicketsList"
            :item-class="itemStatusColor"
          />
        </v-card-text>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "CustomerTickets",
  props: ["edit", "add", "tickets"],
  data() {
    return {
      customerTicketsList: this.tickets,
    };
  },
  computed: {
    columns() {
      return [
        {
          sortable: false,
          text: "Order Id",
          value: "order_id",
          width: "40px",
          sortable: false,
        },
        {
          sortable: false,
          text: "User Name",
          value: "user_name",
          width: "170px",
        },
        {
          sortable: false,
          text: "Subject Line",
          value: "subject_line",
          width: "170px",
        },
        {
          sortable: false,
          text: "Description",
          value: "description",
          width: "170px",
        },
        {
          sortable: false,
          text: "Status",
          value: "ticket_status",
          width: "170px",
        },
      ];
    },
  },
  methods: {
    itemStatusColor: function (item) {
      if (item.ticket_status == "Open") {
        return "pending";
      } else if (item.ticket_status == "Inprocess") {
        return "pickup";
      }
      if (item.ticket_status == "Close/Resolved") {
        return "completed";
      } else {
        return;
      }
    },
  },
  mounted() {
    console.log(this.customerTicketsList);
  },
};
</script>

<style>
.pending {
  background-color: #ff000021;
}
.active {
  background-color: #fffe0026;
}
.pickup {
  background-color: #fffe0026;
}
.completed {
  background-color: #00800026;
}
</style>
